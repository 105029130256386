import { IFaq } from "@/types";
import { generateJsonLd } from "@/utils";
import { useTranslation } from "next-i18next";

export default function FaqPageJsonLd({ items }: { items: IFaq[] }) {
  const { i18n } = useTranslation();

  const jsonLdFaq = generateJsonLd("FAQPage", i18n.language, {
    mainEntity: items.map((item: IFaq) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer.join().replace(/<[^>]*>/g, ""),
      },
    })),
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLdFaq),
      }}
    />
  );
}
